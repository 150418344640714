import React from 'react'
import Head from 'next/head'
import { Link, Button } from '@sm/client/components'

function NotFoundPage() {
	return (
		<div className="view view--error">
			<Head>
				<title>{`404 | Showmanager`}</title>
			</Head>
			<main className="view__main">
				<h1 className="error__title">
					<span className="error__code">404</span>
					<span className="error__message">Page not found</span>
				</h1>
				<div className="error__description">
					Die angeforderte Seite existiert nicht.
				</div>
				<div className="error__actions">
					<Button primary onClick={() => window.history.back()}>
						Zurück
					</Button>
				</div>
			</main>
		</div>
	)
}

export default NotFoundPage
